<div class="header">
  <h2 style="font-size: 1em;">{{ 'VEHICLE_INFO.SECURITY_PRIVACY_TITLE' | translate}}</h2 >
</div>

<div class="content" style="height: 200px; overflow-y: scroll; border: 1px solid gray; padding: 10px; margin-bottom: 10px;">
  <p style="font-size: 0.6em;">{{ 'VEHICLE_INFO.SECURITY_PRIVACY_BODY_P1' | translate}}</p>
  <p style="font-size: 0.6em;">{{ 'VEHICLE_INFO.SECURITY_PRIVACY_BODY_P2' | translate}}</p>
  <p style="font-size: 0.6em;">{{ 'VEHICLE_INFO.SECURITY_PRIVACY_BODY_P3' | translate}}</p>
  <p style="font-size: 0.6em;">{{ 'VEHICLE_INFO.SECURITY_PRIVACY_BODY_P4' | translate}}</p>
  <ul style="font-size: 0.6em;">
    <li><a href="https://www.parkingpass.com/privacy-policy/?utm_source=saas_parking_pass&utm_medium=organic&utm_campaign=resident_registration" target="_blank">https://www.parkingpass.com/privacy-policy/</a></li>
    <li><a href="https://www.parkingpass.com/data-retention-policy/?utm_source=saas_parking_pass&utm_medium=organic&utm_campaign=resident_registration" target="_blank">https://www.parkingpass.com/data-retention-policy/</a></li>
  </ul>
  <p style="font-size: 0.6em;">{{ 'VEHICLE_INFO.SECURITY_PRIVACY_BODY_2' | translate}}</p >
</div>

<div style="text-align: right;">
  <button (click)="closeDialog()" align-right>{{ 'GENERAL.UNDERSTAND' | translate}}</button>
</div>
